export default {
  HOME: "HOME",
  Languages: "Languages",
  "COMPASS: enhancing motivation of older learners to learn through cultural materials":
    "COMPASS: avvicinarsi alla cultura a tutte le età",
  "Project number 2020-1-DE02-KA204-007391":
    "Progetto n.: 2020-1-DE02-KA204-007391",
  "COMPASS is an initiative born from the need identified in all partner countries to motivate older learners to take a more active part in lifelong learning. In our current globalized world foreign languages are an essential vehicle to ensure communication among societies. In parallel, it is of human nature to feel identified and represented by a culture, a society, a community; these define our values, beliefs and way of relating to the others and the world. Being able to express such identifications by story-telling and creating of narratives in foreign languages is indeed a source of motivation and inspiration.":
    "COMPASS è un'iniziativa nata per motivare l'apprendimento a tutte le età, in particolar modo per familiarizzare con le lingue straniere come strumento di comunicazione tra le società. Allo stesso tempo, fa parte della nostra natura essere rappresentati da cultura, da regole sociali, dalle comunità che, in questo modo, ci permettono di definire valori, credenze e relazioni. Riuscire a comunicare tutto questo in una lingua straniera è fonte continua di ispirazione e motivazione.",
  "As an educator, the COMPASS project will help you combine cultural heritage with foreign language learning in a very creative way and with a CLIL approach. You will learn how to embed digital practices in your educational activities, experiment with cultural narratives in our digital books and find out about cultural heritage sites thanks to our geocaching routes! ":
    "Come educatori, il progetto COMPASS ti orienta rispetto all'integrazione tra apprendimento sul patrimonio culturale e insegnamento di una lingua straniera (cosiddetto approccio CLIL). Inoltre, vogliamo mettere a disposizione strumenti digitali funzionali alle tue attività educative, sia per sperimentare come raccontare in maniera creativa il patrimonio culturale dei luoghi d'Europa, sia come esplorarlo con l'uso di mappe personalizzate per una caccia al tesoro senza età!",
  "COMPASS materials will help you extend your professional competences and be more able to motivate older people to engage in lifelong learning.":
    "COMPASS significa migliorare le proprie competenze relative all'educazione per la terza età, perché non si smette mai di imparare!",
  "Start browsing around all our web sections on this platform and find out all materials and COMPASS project has to offer you!":
    "Esplora ora la nostra piattaforma e scegli quale materiale COMPASS è più adatto alle tue esigenze e curiosità!",
  "The project": "The project",
  TIMELINE: "IL CALENDARIO DELLE ATTIVITÀ",
  "Online Kick-off meeting: January 2021":
    "#1 incontro di progetto - Germania 2021",
  "Second Transnational meeting: Ogre (Latvia)  ":
    "#2 incontro di progetto - Ogre (Lettonia)",
  "TOOL BANK - November 2021\n\n": "RACCOLTA DI BUONE PRATICHE - novembre 2021",
  "Third Transnational Meeting: Reus (Spain) ":
    "#3 incontro internazionale: Reus (Spagna)",
  "Learning and Training Activity: Cori (Italy)":
    "Attività di formazione internazionale (Cori, Italia)",
  "BLENDED COURSE - August 2022": "Corso di formazione - agosto 2022",
  "Fourth Transnational Meeting: Rijeka (Croatia)":
    "#4 incontro internazionale: Fiume (Croazia)",
  "DIGITAL BOOK - February 2023": "MANUALE DIGITALE - febbraio 2023",
  "Fifth Transnational Meeting: Aveiro, (Portugal)":
    "#5 incontro internazionale: Aveiro (Portogallo)",
  "GEOCATCHING CULTURAL ROUTE - August 2023":
    "CACCIA AL TESORO DIGITALE (GEOCACHING) - agosto 2023",
  "Multiplier events: Germany, Portugal, Spain, Italy and Croatia":
    "Eventi moltiplicatori: Germania, Portogallo, Spagna, Italia e Croazia",
  "TOOL BANK": "RACCOLTA DI BUONE PRATICHE",
  "The tool bank will include the practical examples of CLIL activities ready to be deployed by adult educators. These will be activities which will combine cultural heritage background and items to teach foreign languages. As such, it will become a great means to extend professional competences of adult educators in order to provide them with the necessary knowledge to improve language and intercultural skills of their adult learners and trigger their motivation to learn.":
    "Questo database include gli esempi pratici di attività che integrano apprendimento linguistico e culturale, pronte per educatori per adulti. Queste attività sono ideali per allargare le proprie competenze in modo da definire le conoscenze necessarie di tipo linguistico e interculturali funzionali al rendere maggiormente motivati gli adulti nell'educazione permanente.",
  "Expected in November 2021": "Torna a novembre 2021!",
  "BLENDED COURSE": "CORSO DI FORMAZIONE",
  "If you are an educator working with older adults learning foreign languages, have a look at our new and innovative course “Cultural Narratives as a motivational trigger for learning in older learners”and learn everything you need to exploit cultural narrative and story-telling as a motivational trigger to engage older learners in lifelong learning.":
    "Se lavori o vuoi lavorare nella terza età con focus rispetto alle lingue straniere, da' un'occhiata al corso innovativo \"Processi narrativi culturali come motivazione per la formazione permanente della terza età\". Affronterai principalmente un percorso su strategie legate allo storytelling, a tecniche di narrazione come strumenti di facilitazione dell'apprendimento a ogni età.",
  "Take advantage of everything EU cultural heritage has to offer to keep your language students motivated with a CLIL approach! Learn how to activate your learners’ creativity and self-expression by creating safe learning environments where they will be able to share their life-stories and cultural narratives.":
    "studenti motivàti con l'approccio CLIL! Attiva il loro interesse ora",
  "START THE COURSE!": "INIZIA IL CORSO",
  "Expected in August 2022": "Torna ad agosto 2022",
  "DIGITAL BOOK": "MANUALE DIGITALE",
  "“Constructing Identity through Cultural memory”":
    '"Definire l\'identità attraverso la cultura"',
  "Only if you are familiar with the topic and you have gone through the course materials, you are ready to start experimenting with cultural narratives!":
    "Appena completerai il corso, puoi iniziare a sperimentare tutto un nuovo modo di usare il patrimonio culturale come strumento di apprendimento!",
  "Adults involved in COMPASS training activities have developed a Digital Book through a collaborative process in their local communities collecting and sharing knowledge and information about tangible and intangible culture elements in their communities and creating cultural narratives that will make all of us feel identified with the EU society.":
    "Chi farà parte del corso di formazione COMPASS parteciperà anche nel mettere in evidenza il patrimonio culturale delle buone pratiche esistenti nelle comunità locali. Attraverso un processo partecipato, è possibile condividere informazioni e conoscenze rispetto al patrimonio tangibile e intangibile ed essere parte della grande narrazione europea.",
  "These books will not only reflect the way older people relate and identify themselves with the cultural heritage of their communities but also serve as a practical tool for language learning.":
    "Tale manuale sarà innanzitutto uno strumento pratico per definire percorsi di apprendimento linguistico, come anche uno strumento di riflessione su patrimonio e comunità locali.",
  "Expected in February 2023": "Torna a febbraio 2023!",
  "GEOCATCHING CULTURAL ROUTE": "CACCIA AL TESORO DIGITALE (GEOCACHING)",
  "Have a look at our virtual geocaching cultural routes to discover European Cultural Heritage through the usage of fun digital technologies. Thanks to dynamical quests, quizzes, QR codes, digital maps and a multi-player chat-bot-based channel, you will be able to visit Europe from your home.":
    "Prova i sentieri per scoprire le vie della cultura europea attraverso il geocaching! In maniera molto semplice, è possibile mettere su una caccia al tesoro digitale e reale mediante l'uso di domande, quiz, QR e mappe digitali all'interno di canali multigiocatore, in modo da visitare l'Europa in maniera divertente e collettiva.",
  "Cultural Heritage sites have never been more accessible to all. Ready for some new experiences?":
    "Il patrimonio culturale non è mai stato così accessibile. Pronti a nuove esperienze?",
  "Expected in August 2023": "Ritorna ad agosto 2023!",
  NEWS: "News",
  "Read about COMPASS latest activities and events. Stay tuned and follow us on all our social media channels!":
    "Leggi di più sulle novità di COMPASS! Rimani in contatto con noi sui social!",
  "OUR PARTNERS": "I NOSTRI PARTNER",
  "Would you like to meet the whole team? These are the e-heritage partners:":
    "Questi sono i partner per il patrimonio digitale! Scoprili qui:",
  "CONTACT US": "CONTATTACI",
  "Hello! Let's talk!": "Ciao, sentiamoci!",
  "We would love to hear from you. Drop us , whether it is a comment, a question, a work proposition or just to say hello. Please, use the form below.":
    "Scrivici, sia per un commento, una domanda, una proposta di collaborazione o anche solo per salutarci. Usa il modulo qui sotto.",
  "Name & Surname": "Nome e Cognome",
  "E-mail Address": "Indirizzo email",
  Subject: "Oggetto",
  Message: "Messaggio",
  "Send Message": "Invia",
  Duration: "Durata",
  "Activity description": "Descrizione dell’attività",
  "Training method": "Metodologia formativa",
  "Materials/Equipment": "Materiale",
  Module: "Module",
  "Back to modules": "Back to modules",
  "Click Here!": "Clicca qui!",
  "GEOCACHING CULTURAL ROUTE": "GEOCACHING CULTURAL ROUTE",
  "GCRp1": "Il Compass Geocaching Culture Route è un gioco basato sul classico concetto di \"caccia al tesoro\", in cui le squadre competono tra loro per raggiungere i luoghi il più velocemente possibile e risolvere domande dinamiche a quiz. L'obiettivo è quello di sostenere i partecipanti nell'apprendimento della lingua attraverso un metodo creativo, stabilendo contemporaneamente un legame culturale.",
  "GCRp2": "COME ORGANIZZARE IL PROPRIO GIOCO CON GLI ANZIANI?   ",
  "GCRp2a": " Suggerimenti per gli insegnanti: ",
  "GCRp3": "Assicuratevi di trovare un luogo facilmente accessibile.",
  "GCRp4": "Create il vostro percorso stampando e posizionando i codici QR generati dal bot. Per sperimentare i propri percorsi, è possibile utilizzare il codice per creare i percorsi::  ",
  "GCRp5": "Sostenere ogni gruppo di adulti con un capogruppo che guidi il gruppo nell'uso della tecnologia.",
  "GCRp6": "Se avete bisogno di assistenza per creare il percorso culturale di Geocaching di Compass, non esitate a contattare i nostri partner in Italia: Indirizzo: APS Polygonal, Via Elio Filippo Accrocca 1, 04010 Cori.  ",
  "GCRp4a": "https://github.com/",
  "GCRp7": "Se desiderate sperimentare di persona l'itinerario culturale di Compass Geocaching, già creato nell'ambito del progetto, potete contattare i seguenti contatti:",
  "GCRp7a": "Germania: KVHS Norden, Customer Team, Uffenstraße 1, 26506 Norden, Tel. +49 49311870123 o info@kvhs-norden.de.",
  "GCRp7b": "Croazia: Volkshochschule Dante, Wenzelova 2, 51000, RIJEKA.  ",
  "GCRp7c": "Lettonia: Fondazione per l'istruzione e l'innovazione \"Zini\", Brivibas Street, 117-42, LV-5001, Riga.  ",
  "GCRp7d": "Portogallo: UNAVE, Associazione per la formazione professionale e la ricerca dell'Università di Aveiro, Campus Universitariosde Santiago, 3810-193 Aveiro.  ",
  "GCRp7e": "Universidade de Aveiro, Campus Universitário de Santiago, 3810-193 Aveiro.",
  "GCRp7f": "Italia: APS Polygonal, Via Elio Filippo Accrocca 1, 04010 Cori.",
  "GCRp7g": "Spagna: DOMSPAIN CONSULTING SL, RAVAL SANT PERE, 1 ENTRESOL, 43201 Reus.",
  "GCRp8": "Esempio dal progetto: Tutte le mappe da noi sviluppate sono completamente tradotte, consentendo ai partecipanti di confrontare e imparare facilmente le parole.",
  "GCRp9": "SCARICA LE MAPPE DELL'EUROPA",
  "GCRp10": "⬇️ Germania",
  "GCRp11": "⬇️ Croazia",
  "GCRp12": "⬇️ Lettonia",
  "GCRp13": "⬇️ Portogallo",
  "GCRp14": "⬇️ Italia",
  "GCRp15": "⬇️ Spagna",
  "GCRp16": "Come giocare ai percorsi di geocaching culturale:",
  "GCRp17": "Abbiamo sviluppato uno strumento digitale per promuovere l'invecchiamento attivo e facilitare l'acquisizione delle lingue, rendendo l'apprendimento linguistico più piacevole e collegato al patrimonio culturale. Abbiamo creato un'interessante guida che descrive con un linguaggio semplice le località coperte da COMPASS. Abbiamo sviluppato quiz di supporto all'apprendimento delle lingue che possono essere facilmente compresi da studenti di tutte le età, enfatizzando la semplicità e la chiarezza del linguaggio. Utilizzando i chatbot di Telegram, abbiamo progettato una chat di caccia al tesoro non convenzionale in cui i partecipanti vengono messi alla prova con semplici compiti di conoscenza e hanno l'opportunità di esplorare i modelli di patrimonio culturale selezionati dagli educatori COMPASS al proprio ritmo.  ",
  "GCRp19": "Gioco:",
  "GCRp20": "Gruppi/squadre:",
  "GCRp21": "Tutti i giocatori in una sessione di gioco formano squadre (idealmente composte da 2-10 persone) che competono tra loro.",
  "GCRp22": "Ogni squadra sceglie un caposquadra che rappresenta l'intera squadra durante il gioco. Il caposquadra interagisce con il bot di gioco attraverso il proprio account Telegram.",
  "GCRp23": "Gli altri giocatori della stessa squadra aiutano il caposquadra a raggiungere i luoghi, a cercare i codici QR e a risolvere le domande del quiz dinamico.  ",
  "GCRp24": "Registrazione/iscrizione:",
  "GCRp25": "Per partecipare a una partita, il caposquadra deve inizialmente scansionare il codice QR di registrazione per conto della propria squadra. Il codice QR viene distribuito dagli organizzatori del gioco prima della partita.  ",
  "GCRp26": "Conversazione di registrazione della squadra con il bot.",
  "GCRp27": "Con la successiva conversazione con il bot verranno poste le seguenti domande:",
  "GCRp28": "1. Un semplice quiz introduttivo per verificare che i giocatori comprendano le regole del gioco,",
  "GCRp29": "2. Il nome della squadra,",
  "GCRp30": "3. Il numero dei membri del team (incluso il leader del team),",
  "GCRp31": "4. Un’immagine avatar che rappresenta la squadra (o un’immagine della squadra stessa).",
  "GCRp32": "STRUTTURA DEL GIOCO",
  "GCRp33": "Squadre che raggiungono luoghi diversi.",
  "GCRp34": "Tutte le squadre si riuniscono nel luogo di partenza della partita, in un determinato orario, fornito dagli organizzatori del gioco prima della partita.",
  "GCRp35": "Gli organizzatori mostreranno ai giocatori il QR Code di partenza (ovvero il codice che identifica il luogo di partenza).",
  "GCRp36": "Non appena i leader della squadra scannerizzeranno il codice, il gioco inizierà.",
  "GCRp37": "Il gioco è strutturato come segue:",
  "GCRp38": "1. La squadra raggiunge una posizione (la posizione di partenza o qualsiasi intermedia),",
  "GCRp39": "2. La squadra scatta un selfie, che serve come prova del raggiungimento del luogo,",
  "GCRp40": "3. Viene assegnato e risolto dai giocatori un quiz (la risposta corretta dovrà essere inviata al bot come un semplice messaggio di testo, se la soluzione data è sbagliata la squadra dovrà attendere 30 secondi prima di riprovare),",
  "GCRp41": "4. Viene assegnata la posizione successiva, andare al passo (1); se tutte le posizioni sono state raggiunte, viene assegnata l’ultima posizione,",
  "GCRp42": "5. Selfie dell’ultima squadra",
  "GCRp43": "6. Il quiz finale viene assegnato e risolto dal team,",
  "GCRp44": "7. Il gioco è finito",
  "GCRp45": "Quiz assegnati e risolti dai giocatori.",
  "GCRp46": "Quiz finale assegnato e risolto dal team.",
  "GCRp47": "REQUISITI PRIMA DI INIZIARE",
  "GCRp48": "Il percorso culturale Compass Geocaching si avvale della piattaforma di messaggistica istantanea Telegram per offrire un’esperienza di gioco accessibile e di facile fruizione, sia per i giocatori che per gli amministratori del gioco. Il gioco si basa sul classico schema della “caccia al tesoro”, con il giocatore squadre che si sfidano alla ricerca di luoghi specifici e risolvendo quiz.",
  "GCRp49": "Ogni luogo di gioco è identificato da un codice a barre quadrato (il cosiddetto “QR Code”).",
  "GCRp50": "COSA TI SERVE PER GIOCARE",
  "GCRp51": "I seguenti requisiti devono essere posseduti dall’amministratore del gioco e da almeno una persona per ogni squadra (il cosiddetto “team leader”).",
  "GCRp52": "📱 Smartphone (con batteria completamente carica 🔋),",
  "GCRp53": "👨‍💼 Account Telegram,,",
  "GCRp54": "🌐 Connessione dati,",
  "GCRp55": "📷 App per scanner di codici QR..",
  "GCRp56": "Leggi sotto per ulteriori dettagli.",
  "GCRp57": "SMARTPHONE",
  "GCRp58": "Per giocare è possibile utilizzare qualsiasi smartphone recente dotato di GPS e fotocamera.",
  "GCRp59": "Compass Geocaching Cultural Route è stato testato con successo sui seguenti sistemi operativi: iOS o Android.",
  "GCRp60": "ACCOUNT TELEGRAM",
  "GCRp61": "Telegram è un sistema di messaggistica istantanea (proprio come WhatsApp o Facebook Messenger), disponibile gratuitamente su App Store e Google Play Store.",
  "GCRp62": "Per poter utilizzare Telegram è necessario innanzitutto registrarsi e attivare un account utente, operazione che può essere effettuata anche gratuitamente tramite l’applicazione, fornendo un numero di cellulare funzionante.",
  "GCRp63": "Solo gli amministratori e i team leader necessitano di un account Telegram. Tuttavia, il percorso culturale Compass Geocaching consente a chiunque di seguire un gioco attivo attraverso i “canali pubblici” di Telegram, ai quali qualsiasi utente di Telegram può iscriversi.",
  "GCRp64": "CONNESSIONE DATI",
  "GCRp65": "Il gioco si basa su una conversazione con un “bot” di Telegram che raccoglie informazioni e fornisce istruzioni ai giocatori. Questa comunicazione richiede una connessione dati attiva.",
  "GCRp66": "In alcuni ambienti interni potrebbe essere presente una copertura cellulare discontinua o scarsa: in questo caso potrebbe essere utile verificare la presenza di connessioni Wi-Fi. Ricorda che la comunicazione con un bot di Telegram è opportunistica: se scansioni un codice QR o fornisci una soluzione a un quiz mentre non è disponibile una connessione dati, Telegram ristabilirà la comunicazione il prima possibile.",
  "GCRp67": "CARICA BATTERIE",
  "GCRp68": "Scansionare i codici QR, usare il GPS e comunicare con il bot richiede energia: assicurati che lo smartphone del caposquadra sia completamente carico prima di iniziare il gioco. Potrebbe essere utile portare con te un caricatore USB portatile.",
  "GCRp69": "SCANNER QR CODE",
  "GCRp70": "Uno scanner di codici QR è un’applicazione per telefoni cellulari che scansiona i codici a barre utilizzati dal gioco per identificare le posizioni.",
  "GCRp71": "Innanzitutto, assicurati che il tuo sistema operativo supporti immediatamente la scansione del codice QR. Apri l’app Fotocamera e puntala verso il seguente codice QR. Su iOS o versioni recenti di Android, dovrebbe essere visualizzato un collegamento cliccabile in sovrapposizione.",
  "GCRp72": "Dopo aver fatto clic sul collegamento, verrà avviata l’app Telegram. Fare clic su “Avvia” per inviare il collegamento al bot, che fornirà la conferma che lo scanner del codice QR funziona.",
  "GCRp73": "Se l’app della fotocamera non funziona, dovrai installare un’app per la scansione del codice QR.",
  "disclaimer1": "Il supporto della Commissione Europea per la produzione di questa pubblicazione non costituisce un'approvazione dei contenuti, i quali riflettono unicamente le opinioni degli autori. La Commissione non può essere ritenuta responsabile dell'uso derivato delle informazioni qui contenute.",
  "disclaimer2": "Nonostante un'attenta ricerca, è possibile che le immagini utilizzate siano protette da copyright. Se sei l'autore di una delle immagini utilizzate e le nostre informazioni sull'utilizzo non sono corrette, contattaci.   "
}
